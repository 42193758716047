<template>
  <div id="app">
    <MainItem/>
  </div>
</template>


<script>
import MainItem from './components/MainItem.vue'

export default {
  name: 'App',
  components: {
    MainItem
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Merriweather&family=Roboto&family=Roboto+Mono&display=swap');

#app {
  font-family: 'Merriweather', serif;
  color: #2c3e50;
}
</style>
